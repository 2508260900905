<template>
  <div class="quiz-container">
    <h1>Case Study: Home Security Video Marketing</h1>
    <div class="case-study">
      <h2>Overview</h2>
      <div class="overview-section">
        <p>Video marketing plays a crucial role in generating leads for affiliate campaigns. 
           This case study focuses on storytelling, creativity, and delivering a compelling call-to-action.</p>
        
        <div class="offer-description">
          <h3>Home Security Lead Generation</h3>
          <p>The home security market represents a perfect opportunity for video marketing. With rising concerns about 
             property safety and the advancement of smart security systems, there's a growing demand for professional 
             security solutions. Your video content will tap into homeowners' emotional needs for safety and peace of mind, 
             while presenting modern security systems as an accessible and necessary solution.</p>
        </div>
      </div>
      
      <h2>Example Campaign Videos</h2>
      <div class="video-examples">
        <div class="video-container">
          <iframe 
            src="https://drive.google.com/file/d/19UCc48decMaJvTTUH0VCnGB7Nv6794FG/preview" 
            allow="autoplay"
            allowfullscreen>
          </iframe>
        </div>
        
        <div class="video-container">
          <iframe 
            src="https://drive.google.com/file/d/1X7MXnK6TTddpDulFRnmJsXGZ4ODDw1dj/preview" 
            allow="autoplay"
            allowfullscreen>
          </iframe>
        </div>

        <div class="video-container">
          <iframe 
            src="https://drive.google.com/file/d/1GqG2nkBjvg06WJgJTDuJaYFBDfRmcpGu/preview" 
            allow="autoplay"
            allowfullscreen>
          </iframe>
        </div>

        <div class="video-container">
          <iframe 
            src="https://drive.google.com/file/d/1voMIrQTOasH6Yuz4VgcbS_ThuF-TCu2F/preview" 
            allow="autoplay"
            allowfullscreen>
          </iframe>
        </div>

        <div class="video-container">
          <iframe 
            src="https://drive.google.com/file/d/1WM5dgbpMokR-Po0QsLJG5M2EA6jjrASH/preview" 
            allow="autoplay"
            allowfullscreen>
          </iframe>
        </div>
      </div>
      
      <div class="example-script">
        <h3>Example Script:</h3>
        <p>"In just seconds, it's gone. Your delivery... your peace of mind...
           What if this wasn't just a package? What if it was your home?
           It's not a matter of if, but when.
           Protect what matters most. With MyHomeSicur, your home is guarded 24/7, 
           whether you're home or not.
           Take control today. Visit Homesicur.com for a free home security consultation. 
           Don't wait for a wake-up call."</p>
      </div>
      
      <h3>Key Elements:</h3>
      <ul>
        <li>Emotional Hook: Captures attention with relatable scenarios</li>
        <li>Sense of Urgency: Highlights the inevitability of risk</li>
        <li>Solution: Introduces MyHomeSicur as the answer</li>
        <li>Clear Call-to-Action: Guides viewers to take immediate steps</li>
      </ul>
      
      <div class="button-container">
        <button class="btn-back" @click="$emit('back')">← Back</button>
        <button class="btn-primary" @click="$emit('continue')">
          Continue to Offer Selection
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CaseStudy'
}
</script>

<style scoped>
.overview-section {
  background-color: rgba(106, 90, 205, 0.1);
  padding: 25px;
  border-radius: 12px;
  margin: 20px 0;
}

.offer-description {
  margin-top: 20px;
}

.button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
}

.btn-back {
  background: none;
  border: none;
  color: var(--accent-purple);
  cursor: pointer;
  font-size: 16px;
  padding: 10px 20px;
}

.btn-back:hover {
  text-decoration: underline;
}

.video-examples {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  margin: 20px 0;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  margin-bottom: 20px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 8px;
}
</style> 