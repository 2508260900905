<template>
  <quiz-app></quiz-app>
</template>

<script>
import QuizApp from './components/QuizApp.vue'

export default {
  name: 'App',
  components: {
    QuizApp
  },
  data() {
    return {
      userInfo: null
    }
  },
  methods: {
    handleRegistrationComplete(formData) {
      this.userInfo = formData
      this.currentStep = 'case-study'
    },
    // ... other methods
  }
}
</script> 