<template>
  <div class="quiz-container">
    <div class="content-wrapper">
      <h1 class="main-title">Video Production</h1>
      <div class="registration-form">
        <h2 class="form-title">Registration</h2>
        <form @submit.prevent="handleSubmit">
          <div class="form-group">
            <label for="name">Full Name</label>
            <input 
              type="text" 
              id="name" 
              v-model="formData.name" 
              required
              placeholder="Enter your full name"
            >
          </div>
          <div class="form-group">
            <label for="email">Email</label>
            <input 
              type="email" 
              id="email" 
              v-model="formData.email" 
              required
              placeholder="Enter your email"
            >
          </div>
          <button type="submit" class="btn-primary">
            Start Quiz
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<style scoped>
.content-wrapper {
  max-width: 600px;
  margin: 0 auto;
  padding: 40px 0;
  width: 100%;
}

.main-title {
  text-align: center;
  margin-bottom: 30px;
  color: var(--primary-text);
}

.form-title {
  text-align: center;
  margin-bottom: 30px;
  color: var(--accent-purple);
}

.registration-form {
  padding: 40px;
  width: 100%;
  background-color: hsla(0, 0%, 100%, 0.05);
  border-radius: 12px;
}

.form-group {
  margin-bottom: 25px;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

input {
  width: 100%;
  padding: 12px;
  border: 1px solid hsla(0, 0%, 100%, 0.1);
  background-color: hsla(0, 0%, 100%, 0.05);
  color: var(--primary-text);
  border-radius: 4px;
  transition: all 0.3s ease;
}

input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.btn-primary {
  display: block;
  margin: 30px auto 0;
  min-width: 200px;
}

/* Add responsive adjustments */
@media (max-width: 768px) {
  .content-wrapper {
    padding: 20px 0;
  }
  
  .registration-form {
    padding: 30px 20px;
  }
}
</style>

<script>
export default {
  name: 'RegistrationForm',
  data() {
    return {
      formData: {
        name: '',
        email: ''
      }
    }
  },
  methods: {
    handleSubmit() {
      window.grecaptcha.ready(() => {
        window.grecaptcha.execute('6Ld8f5oqAAAAABwq453Vnjhc552GVtMY3wWSNsQ7', {action: 'submit'})
          .then((token) => {
            console.log('reCAPTCHA token:', token)
            this.$emit('registration-complete', this.formData)
          })
          .catch((error) => {
            console.error('reCAPTCHA Error:', error)
            alert('Error verifying you are human. Please try again.')
          })
      })
    }
  }
}
</script> 